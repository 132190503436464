<template>
   <div class="row m-1 px-1">
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Merge Duplicated MSKU
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To merge the duplicated MSKU's, please click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-xl-6">
                <label>&nbsp;</label>
                <dx-util-button
                  text="Merge Duplicated MSKU"
                  type="default"
                  styling-mode="contained"
                  :element-attr="btnElementAttr"
                  @click="mergeDuplicatedMsku"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Merge MSKUs With Hash Code
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To find and merge duplicated MSKUs with same product, please click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-xl-6">
                <label>&nbsp;</label>
                <dx-util-button text="Merge MSKUs With Hash Code" type="default"
                  styling-mode="contained" :element-attr="btnElementAttr"
                  @click="mergeMskusWithHashCode"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Merge Duplicated MSKU
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To merge the duplicated MSKU's, please click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-xl-6">
                <label>&nbsp;</label>
                <dx-util-button
                  text="Merge Duplicated MSKU"
                  type="default"
                  styling-mode="contained"
                  :element-attr="btnElementAttr"
                  @click="mergeDuplicatedMsku"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Merge Duplicated Products
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To find and merge duplicated products click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-xl-6">
                <label>&nbsp;</label>
                <dx-util-button
                  text="Merge Duplicated Products"
                  type="default"
                  styling-mode="contained"
                  :element-attr="btnElementAttr"
                  @click="mergeDuplicatedProducts"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Fill In Empty UPC From Amazon
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-12 mb-1">
                <p class="my-0">
                  To fill in the empty UPCs for products, please enter the <b class="text-warning"> STORE ID </b> and click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-12 col-sm-6">
                <label for="storeID">Store ID</label>
                <dx-util-number-box id="storeID" v-model="storeID" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Store ID is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-12 col-sm-6 pl-sm-1">
                <label>&nbsp;</label>
                <dx-util-button text="Fill In Empty UPC From Amazon" type="default"
                  styling-mode="contained" :element-attr="btnElementAttr"
                  @click="fillEmptyUpcFromAmazon"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 px-0 pb-1">
      <div class="card card-psh border m-half h-100">
        <div class="bg-light-primary rounded-top p-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-upc" size="36px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Fix Parent - Child ASIN
              </h4>
              <p class="card-text mb-0">
                Product Code Maintenance
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <dx-util-validation-group>
            <div class="row">
              <div class="col-md-12 mb-1">
                <p class="my-0">
                  To fix parent & child ASIN issues, please enter the <b class="text-warning"> COMPANY ID, OLD ASIN, and NEW ASIN </b>, then click the button below.
                </p>
              </div>
            </div>
            <div class="form-row align-items-end">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                <label for="companyID">Company ID</label>
                <dx-util-number-box id="companyID" v-model="companyID" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Company ID is required" />
                  </dx-util-validator>
                </dx-util-number-box>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                <label for="oldAsin">Old ASIN</label>
                <dx-util-text-box id="oldAsin" v-model="oldAsin" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="Old ASIN is required" />
                    <dx-util-custom-rule :validation-callback="checkOldAsin" message="Invalid ASIN" />
                  </dx-util-validator>
                </dx-util-text-box>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                <label for="newAsin">New ASIN</label>
                <dx-util-text-box id="newAsin" v-model="newAsin" styling-mode="filled">
                  <dx-util-validator>
                    <dx-util-required-rule message="New ASIN is required" />
                    <dx-util-custom-rule :validation-callback="checkNewAsin" message="Invalid ASIN" />
                  </dx-util-validator>
                </dx-util-text-box>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                <label>&nbsp;</label>
                <dx-util-button text="Fix Parent - Child ASIN" type="default"
                  styling-mode="contained" :element-attr="btnElementAttr"
                  @click="fixParentChildIssue"
                />
              </div>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maintenanceService from '@/http/requests/common/maintenanceService'
import { Notify } from '@robustshell/utils/index'
import { isValidAsin } from '../../ship/validationItem.js'

export default {
  data() {
    return {
      accountNo: null,
      storeID: null,
      companyID: null,
      oldAsin: null,
      newAsin: null,
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  methods: {
    checkNewAsin() {
      return isValidAsin(this.newAsin)
    },
    checkOldAsin() {
      return isValidAsin(this.oldAsin)
    },
    mergeDuplicatedMsku() {
      maintenanceService.mergeDuplicatedMsku().then(() => {
        Notify.success('Merge duplicated MSKU successfully')
      }).catch(error => {
        Notify.error(`Merge duplicated MSKU job creation failed. ${error}`)
      })
    },
    mergeMskusWithHashCode() {
      maintenanceService.mergeMskusWithHashCode().then(() => {
        Notify.success('Merge MSKUs with hash code successfully')
      }).catch(() => {
        Notify.error('Merge MSKUs with hash code job creation failed')
      })
    },
    mergeDuplicatedFnsku() {
      maintenanceService.mergeDuplicatedFnsku().then(() => {
        Notify.success('Merge duplicated FNSKU successfully')
      }).catch(() => {
        Notify.error('Merge duplicated FNSKU job creation failed')
      })
    },
    mergeDuplicatedProducts() {
      maintenanceService.mergeDuplicatedProducts().then(() => {
        Notify.success('Merge duplicated Products successfully')
      }).catch(() => {
        Notify.error('Merge duplicated Products job creation failed')
      })
    },
    fixParentChildIssue(e) {
      const params = {
        companyId: this.companyID,
        oldAsin: this.oldAsin,
        newAsin: this.newAsin,
      }
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService.fixParentChildAsinIssue(params).then(() => {
          e.validationGroup.reset()
          Notify.success('Fix parent - child ASIN issue successfully')
        }).catch(error => {
          Notify.error(`Fix parent - child ASIN issue job creation failed: ${error}`)
        })
      }
    },
    fillEmptyUpcFromAmazon(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        maintenanceService.fillEmptyUpcFromAmazon(this.storeID).then(() => {
          Notify.success('Fill in empty UPC from Amazon successfully')
        }).catch(error => {
          Notify.error(`Fill in empty UPC from Amazon job creation failed: ${error}`)
        })
      }
    },
  },

}
</script>
